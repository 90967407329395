// all routes
import Routes from './routes/Routes';
// helpers
import { configureFakeBackend } from './helpers';

// For Default import Theme.scss
import './assets/scss/Theme.scss';

const App = () => {
    configureFakeBackend();
    return (
        <Routes />
    );
   
};
console.log("Current Environment:", process.env.REACT_APP_ENV);

const cloudFunctionBaseUrl = process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL;

console.log("Cloud Function Base URL:", cloudFunctionBaseUrl);

export default App;
